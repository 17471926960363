/* hide scrollbar but allow scrolling */
.column {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.column::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.titleTop{
  position: relative;
}
.titleTop:after {
  content: '';
  width: 20px;
  height: 72.6px;
  background: #fff;
  transform: rotate(135deg);
  position: absolute;
  border-top: 0;
  border-left: 0;
  border-bottom: 0;
  top: auto;
  right: 6px;
  bottom: -4.6px;
}