.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin-top: 20px;
  width: "80%";
}

.container2 {
  display: flex;
  margin-top: 20px;
  width: "80%";
  margin-left: 5%;
}

img:hover {
  cursor: pointer;
}

@media screen and (max-width: 2000px) {
  .container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 900px) {
  .container {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* body {
    background: #000; 
}  */

body::-webkit-scrollbar {
  width: 0.5em;
}

body::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

body::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.stones {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
}

.stones::-webkit-scrollbar {
  width: 0.5em;
  background: #111;
}
.stones::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.stones::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

.stones::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.switchOption {
  margin-inline: 10px;
  display: flex;
  justify-content: center;
  border: 1px solid #049c77;
  border-radius: 5px;
  padding: 5px;
  width: 30px;
  height: 30px;
  align-items: center;
  background: white;
  color: #049c77;
  cursor: pointer;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

.switchOptionSelected {
  margin-inline: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #049c77;
  border-radius: 5px;
  padding: 5px;
  width: 30px;
  height: 30px;
  background: #049c77;
  color: white;
  cursor: pointer;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}
