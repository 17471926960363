.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    background-color: #f5f5f5;
    margin-top: 20px;
}

.feedbacks {
    overflow-y: scroll;
    height: 90%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.feedbacks::-webkit-scrollbar {
    width: 5px;
    background: #aaa;
}

.feedbacks::-webkit-scrollbar-track {
    width: 5px;
    background: #aaa;
}

.feedbacks::-webkit-scrollbar-thumb {
    background: blue;
    width: 10px;
    height: 10px;
    border-radius: 5px;
}

.table-wrapper {
    width: 98vw;
    /* height: 95%; */
    padding-left: 10px;
    padding-right: 10px;
    overflow: scroll;
}

.table-wrapper::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background: #111;
}

.table-wrapper::-webkit-scrollbar-track {
    width: 2px;
    background: #111;
}

.table-wrapper::-webkit-scrollbar-thumb {
    background: blue;
    width: 10px;
    height: 10px;
    border-radius: 5px;
}

.drawerItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding: 10px;
    cursor: pointer;
    /* background: selected ? '#1b4a92' : (hover ? '#ccc' : '#fff'), */
}

.drawerItem:hover {
    background: #ccc;
}

.Active {
    background: #1b4a92;
    color: #fff;
}

.Inactive {
    background: #fff;
    color: #000;
}

.mobileMaxWidth {
    width: 100%;
}

.mobileInputWidth {
    height: 24px;
    width: 80%;
}

.sequencesImg {
    width: 30%;
    transition: transform .25s, visibility .2s ease-out;
}

.sequencesImg:hover {
    transform: scale(2);
    z-index: 1;
    position: absolute;
}

.rptModalAct {
    display: flex;
    justify-content: space-evenly;
}

.table-bordered,
.table-bordered th,
.table-bordered td {
    border: 1px solid gray;
    border-collapse: collapse;
}

.dropActionlnk {
    position: relative;
}

.dropActionlnk::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #0087ca;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.dropActionlnk:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.dropActionlnk:hover{
    font-weight: 500 !important;
}

@keyframes inAnimation {
    0% {
        opacity: 0;
        visibility: hidden;
    }
    100% {
        opacity: 1;
        visibility: visible;
    }
}

@keyframes outAnimation {
    0% {
        opacity: 1;
    }

    100% {
        display: none;
        opacity: 0;
        visibility: hidden;
    }
}

.mounted{
    animation: inAnimation 250ms ease-in;
}

.unmounted{
    animation: outAnimation 270ms ease-out;
    animation-fill-mode: forwards;
}
.userLogo{
    vertical-align: middle;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(212, 212, 212);
}
.userLogo svg {
    height: 16px;
    color: gray;
}
 
.dropActionlnk:hover .userLogo{
    background: rgb(231, 231, 231);
}
